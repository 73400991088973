<template>
  <div>
    <div class="flex xs12 pb-5">
      <p class="display-1 text--center">{{ $t("auth.console_title") }}</p>
    </div>
    <div v-if="!isLogged && !login2FA">
      <form @submit.prevent="doLogin">
        <va-input
          class="mb-3"
          v-model="account.email"
          type="text"
          :label="$t('auth.user_name')"
          :error="!!nameErrors.length"
          :error-messages="nameErrors"
        />

        <va-input
          class="mb-3"
          v-model="account.password"
          type="password"
          :label="$t('auth.password')"
          :error="!!passwordErrors.length"
          :error-messages="passwordErrors"
        />

        <div class="d-flex justify--center mt-3">
          <va-button type="submit" class="my-0">{{
            $t("auth.login")
          }}</va-button>
        </div>
        <va-button
          flat
          :href="'#/auth/resetPassword?redirect=' + baseUrl"
          class="float-left ma-0"
          >{{ $t("auth.forgotten_password") }}</va-button
        >
      </form>
      <!--<div class="d-flex justify--center mt-1">
        <GoogleLogin :callback="googleLogin" />
      </div>-->
    </div>
    <div v-if="!isLogged && login2FA">
      <form @submit.prevent="doLogin2FA">
        <va-input
          class="mb-3"
          v-model="code2FA"
          :label="$t('me_view.code_2FA')"
          type="text"
          :error="!!pin2FAErrors.length"
          :error-messages="pin2FAErrors"
        />

        <div class="d-flex justify--center mt-3">
          <va-button type="submit" class="my-0">{{
            $t("auth.login")
          }}</va-button>
        </div>
      </form>
    </div>
    <div v-if="isLogged">
      <div class="d-flex justify--center mt-3">
        <va-button class="my-0" @click="doLogout">{{
          $t("auth.logout")
        }}</va-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "login",
  data() {
    return {
      account: {
        email: "",
        password: "",
      },
      nameErrors: [],
      passwordErrors: [],
      pin2FAErrors: [],
      login2FA: false,
      code2FA: "",
    };
  },
  computed: {
    ...mapGetters(["isLogged"]),
    formReady() {
      return !this.nameErrors.length && !this.passwordErrors.length;
    },
    baseUrl: {
      get: function () {
        return window.location.protocol + "//" + window.location.host;
      },
    },
  },
  created() {
    this.backend
      .isInstantiated()
      .then((result) => {
        if (result === false) {
          // console.log('NOT Instantiated')
          this.$router.push("/auth/instantiate");
        }
      })
      .catch((err) => {
        // console.log('error getting isInstantiated')
        this.launchToast(this.$t("auth.login_error"), "danger");
      });
    if (this.isLogged === null) {
      this.backend.isLogged().then((r) => {
        if (r !== false) {
          this.login(r);
        } else {
          this.logout();
        }
      });
    }
  },
  methods: {
    ...mapActions(["login", "logout"]),
    // googleLogin(response) {
    //   // console.log(response.credential)
    //   this.backend
    //     .loginGoogle(response.credential)
    //     .then((result) => {
    //       if (result === false) {
    //         this.launchToast(this.$t("auth.login_wrong"), "warning");
    //       } else {
    //         this.login(result);
    //         if (result.loginType === "Admin") {
    //           this.$router.push("/settings/users");
    //         } else {
    //           this.$router.push("/settings/me");
    //         }
    //       }
    //     })
    //     .catch((err) => {
    //       this.launchToast(this.$t("auth.login_error"), "danger");
    //     });
    // },
    doLogin() {
      this.nameErrors = this.account.email
        ? []
        : [this.$t("auth.name_not_present")];
      this.passwordErrors = this.account.password
        ? []
        : [this.$t("auth.password_not_present")];
      if (!this.formReady) {
        return;
      }
      this.backend
        .login(this.account)
        .then((result) => {
          if (result === false) {
            this.launchToast(this.$t("auth.login_wrong"), "warning");
          } else {
            if (result.email) {
              this.login(result);
              if (result.loginType === "Admin") {
                this.$router.push("/settings/users");
              } else {
                this.$router.push("/settings/me");
              }
            } else {
              this.login2FA = true;
            }
          }
        })
        .catch((err) => {
          this.launchToast(this.$t("auth.login_error"), "danger");
        });
    },
    doLogin2FA() {
      if (this.code2FA === "") {
        this.pin2FAErrors = [this.$t("auth.error_code_2FA")];
        return;
      }
      this.backend.login2FA(this.code2FA).then((result) => {
        if (result.status !== false) {
          this.code2FA = "";
          this.pin2FAErrors = [];
          this.login2FA = false;
          this.login(result.data);
          if (result.data.loginType === "Admin") {
            this.$router.push("/settings/users");
          } else {
            this.$router.push("/settings/me");
          }
        } else {
          if (result.data === "invalidPIN") {
            this.pin2FAErrors = [this.$t("auth.error_code_2FA")];
          } else {
            this.launchToast(this.$t("auth.login_error"), "danger");
          }
        }
      });
    },
    doLogout() {
      this.backend
        .logout()
        .then((result) => {
          // console.log('Log out')
          this.logout();
        })
        .catch((err) => {
          this.launchToast(this.$t("auth.login.error", "danger"));
        });
    },
    launchToast(text, col) {
      this.$vaToast.init({
        message: text,
        position: "top-left",
        duration: 4000,
        fullWidth: false,
        color: col,
      });
    },
  },
};
</script>

<style lang="scss"></style>
